import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faPlus,
  faMinus,
  faPause,
  faPlay,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

const FontAwesomeAddToLibrary = () => {
  library.add(
    fab,
    fad,
    faPlus,
    faMinus,
    faPause,
    faPlay,
    faSearch,
    faChevronLeft,
    faChevronRight,
    faTimes
  );

  if (typeof window !== 'undefined') {
    window.___CAANEO___.isFontAwesomeReady = true;
    const neoFontAwesomeReadyEvent = new Event('neoFontAwesomeReady');
    window.dispatchEvent(neoFontAwesomeReadyEvent);
  }
};

export default FontAwesomeAddToLibrary;
